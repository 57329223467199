<template>
  <div class="wrap">
    <CancelModal v-if="cancelStatus" />
    <CheckDontUseIpSpeakerModal
      v-if="isCheckDontUseIpSpeakerModal"
      @closeModal="[(isCheckDontUseIpSpeakerModal = false), (isIpSpeaker = true)]"
    />
    <!-- 중간 저장 팝업 -->
    <CommonConfirm
      v-if="cancelCheckStatus"
      :confirmid="'cancelcheckmodal'"
      @close-modal="onCancelCheckStatusConfirmNo"
      @confirm-modal="onCancelCheckStatusConfirmYes"
    >
      <template #contents>
        <div class="stopmodal_body">
          <h3 class="stopmodal_text1">
            등록중인 스페이스를 저장할까요?
          </h3>
        </div>
      </template>
    </CommonConfirm>
    <!-- 스페이스명 중복 띄우기-->
    <CommonConfirm
      v-if="checkSpaceNm"
      :confirmid="'checkspacename'"
      @close-modal="closeModal"
      @confirm-modal="spaceId === null ? insertSpace() : updateSpaceInfo()"
    >
      <template #contents>
        <div class="stopmodal_body">
          <h3
            class="stopmodal_text1"
            v-html="spaceInfo.spaceNm + '은(는) 이미 등록된 스페이스입니다.<br/>그래도 계속하시겠습니까?'"
          ></h3>
        </div>
      </template>
    </CommonConfirm>
    <searchStore
      v-if="isMap"
      @closeMap="isMap = false"
      @closeMap2="closeMap()"
      @getSpaceInfo="setSpaceInfo"
      :spaceName="spaceName"
    />
    <Franchise
      v-if="franchiseStatus"
      @closeFranchise="closeFranchise()"
      :searchBrandNm="searchBrandNm"
      @insertBrand="insertBrand"
    />

    <div class="space_pc" style="overflow: auto; padding-bottom: 100px">
      <StartPc v-if="startStatus" :userInfo="userInfo" @insertPc="insertStartTime" @closePc="startStatus = false" />
      <EndPc v-if="endStatus" :userInfo="userInfo" @insertPc="insertEndTime" @closePc="endStatus = false" />
      <section class="main myspace w_940">
        <div class="main_box fade-up">
          <div class="main_box_title">
            <h1>스페이스 등록</h1>
            <div @click="linkMySpace()">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div class="cs_main">
            <div class="myspace_content_form">
              <div class="form_text">
                <h3>매장정보</h3>
              </div>
              <div class="pagnation">
                <span></span>
                <span style="width: 12.5%"></span>
              </div>
              <form>
                <label
                  >매장명 <span>*</span>
                  <div>
                    <input
                      type="text"
                      id="search"
                      placeholder="매장명을 검색해주세요."
                      style="padding-right: 20%"
                      v-model="spaceInfo.spaceNm"
                      @keyup.enter="searchAddr('map')"
                      v-if="inputStatus === 'map'"
                    />
                    <input
                      type="text"
                      id="search"
                      placeholder="매장명을 직접 입력해주세요."
                      style="padding-right: 20%"
                      v-model="spaceInfo.spaceNm"
                      v-else
                    />
                    <div class="input_btn">
                      <p id="store_search" style="margin-bottom: 0px" @click="searchAddr('map')" class="on">매장검색</p>
                      <p style="margin-bottom: 0px" @click="searchAddr('search')">직접입력</p>
                    </div>
                  </div>
                </label>
                <label
                  >매장주소 <span>*</span>
                  <div>
                    <input
                      type="text"
                      id="address"
                      placeholder="매장명을 선택하면 자동으로 기입됩니다."
                      v-if="inputStatus === 'map'"
                      v-model="spaceInfo.spaceAddr"
                      :disabled="true"
                    />
                    <input
                      type="text"
                      id="address"
                      placeholder="매장 주소를 검색해주세요."
                      v-else
                      v-model="spaceInfo.spaceAddr"
                      :disabled="true"
                    />
                    <p class="search-addr" @click="searchAddress()">주소검색</p>
                    <input
                      type="text"
                      id="addressDetail"
                      v-model="spaceInfo.spaceAddrDetail"
                      placeholder="상세주소를 입력해주세요."
                    />
                  </div>
                </label>
                <label style="position: relative">
                  소속 브랜드
                  <div class="input_brand">
                    <img src="/media/img/search_icon.svg" alt="" @click="searchBrand" />
                    <input
                      type="text"
                      placeholder="브랜드명을 검색해주세요."
                      v-model="searchBrandNm"
                      @keyup.enter="searchBrand"
                    />
                  </div>
                </label>
                <label id="tel"
                  >연락처
                  <div>
                    <input
                      type="text"
                      maxlength="11"
                      placeholder="매장 연락처를  - 없이 입력해주세요."
                      v-model="spaceInfo.spaceTel"
                      @input="numCheck()"
                    />
                  </div>
                </label>
                <label
                  >사업자등록번호
                  <div>
                    <input
                      type="text"
                      placeholder="사업자등록번호를  - 없이 입력해주세요."
                      v-model="spaceInfo.bno"
                      @keyup.enter="checkBno()"
                    />
                    <p class="btn_type" @click="checkBno()">확인</p>
                  </div>
                </label>
                <div>
                  <label>업종 <span>*</span></label>
                  <div class="select_box">
                    <div class="select-box-area">
                      <div class="label select-box" @mouseover.once="clickSelectBox()" v-if="spaceInfo.sector == null">
                        대분류
                      </div>
                      <div class="label select-box" @mouseover.once="clickSelectBox()" v-else>
                        {{ spaceInfo.sector }}
                      </div>
                      <div class="select-box-dropDown">
                        <ul>
                          <li
                            v-for="item in sectors"
                            :key="item.setorId"
                            @click="getSectorDetails(item.sectorId)"
                            class="optionItem"
                          >
                            <p>{{ item.sectorNm }}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="select-box-area2">
                      <div
                        class="label2 select-box2"
                        @mouseover.once="clickSelectBox2()"
                        v-if="spaceInfo.sectorDetail == null"
                      >
                        소분류
                      </div>
                      <div class="label2 select-box2" @mouseover.once="clickSelectBox2()" v-else>
                        {{ spaceInfo.sectorDetail }}
                      </div>
                      <div class="select-box-dropDown2">
                        <ul>
                          <li v-for="item in sectorDetails" :key="item.sectorDetailId" class="optionItem2">
                            <p>{{ item.sectorDetailNm }}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <label
                  >영업시간 <span>*</span>
                  <div class="set_clock">
                    <div class="start_clock" @click="selectStartTime">
                      <p style="margin-bottom: 0px">
                        {{ userInfo.startTime }}
                      </p>
                    </div>
                    ~
                    <div class="end_clock" @click="selectEndTime">
                      <p style="margin-bottom: 0px">
                        {{ userInfo.endTime }}
                      </p>
                    </div>
                  </div>
                </label>
                <label
                  >IP 스피커 사용여부 <span>*</span>
                  <div
                    style="display: flex; justify-content: flex-start; align-items: center"
                    @click="checkHasSpaceDontUseIpSpeaker()"
                  >
                    <checkboxUseIpSpeaker v-model="isIpSpeaker" />
                    <p style="margin: 0 6px 0">사용</p>
                  </div>
                </label>
              </form>
              <div class="form_bottom space_1">
                <div class="form_bottom_box">
                  <div></div>
                  <div>
                    <!-- <button id="prev_btn" style="text-align: center" @click="linkMySpace()">취소</button> -->
                    <button
                      style="text-align: center"
                      v-if="
                        this.spaceId != null &&
                          spaceInfo.spaceNm != null &&
                          spaceInfo.spaceNm.length > 0 &&
                          spaceInfo.spaceAddr != null &&
                          spaceInfo.spaceAddr !== '' &&
                          spaceInfo.sector != null &&
                          spaceInfo.sectorDetail != null &&
                          spaceInfo.sectorDetail !== '소분류' &&
                          spaceInfo.startDate != null &&
                          spaceInfo.endDate != null
                      "
                      @click="inputStatus === 'search' ? updateCheckSpaceNm() : updateSpaceInfo()"
                    >
                      다음
                    </button>
                    <button
                      :class="{ disabled: disabledInsert === true }"
                      style="text-align: center"
                      v-else-if="
                        this.spaceId == null &&
                          spaceInfo.spaceNm != null &&
                          spaceInfo.spaceNm.length > 0 &&
                          spaceInfo.spaceAddr != null &&
                          spaceInfo.spaceAddr !== '' &&
                          spaceInfo.sector != null &&
                          spaceInfo.sectorDetail != null &&
                          spaceInfo.sectorDetail !== '소분류' &&
                          spaceInfo.startDate != null &&
                          spaceInfo.endDate != null
                      "
                      @click="inputStatus === 'search' ? insertCheckSpaceNm() : insertSpace()"
                    >
                      다음
                    </button>
                    <button v-else style="text-align: center; color: #5b5b5b; background: #4d1b24" disabled>
                      다음
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- 모바일 -->
    <div class="space_mo">
      <StartMobile
        v-if="startStatusM"
        :userInfo="userInfo"
        @closeMobile="startStatusM = false"
        @insertMobile="insertStartTimeM"
      />
      <EndMobile
        v-if="endStatusM"
        :userInfo="userInfo"
        @closeMobile="endStatusM = false"
        @insertMobile="insertEndTimeM"
      />
      <div class="mo_space_header">
        <div class="mo_header_box">
          <svg
            @click="clickPre()"
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.828 7.00072L7.778 11.9507L6.364 13.3647L0 7.00072L6.364 0.636719L7.778 2.05072L2.828 7.00072Z"
              fill="white"
            />
          </svg>
          <h2 style="margin-bottom: 0px">스페이스 등록</h2>
          <div @click="linkMySpace()">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.00023 5.58672L11.9502 0.636719L13.3642 2.05072L8.41423 7.00072L13.3642 11.9507L11.9502 13.3647L7.00023 8.41472L2.05023 13.3647L0.63623 11.9507L5.58623 7.00072L0.63623 2.05072L2.05023 0.636719L7.00023 5.58672Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="pagnation">
          <span></span>
          <span style="width: 12.5%"></span>
        </div>
      </div>
      <section class="main myspace">
        <div class="main_box">
          <div class="cs_main">
            <div class="myspace_content_form bt_pd">
              <form>
                <label
                  >매장명 <span>*</span>
                  <div>
                    <input
                      id="space_name"
                      type="text"
                      placeholder="매장명을 검색해주세요."
                      style="padding-right: 45%"
                      v-if="inputStatus === 'map'"
                      v-model="spaceInfo.spaceNm"
                      @click="searchAddr('map')"
                    />
                    <input
                      id="space_name"
                      type="text"
                      placeholder="매장명을 직접 입력해주세요."
                      style="padding-right: 45%"
                      v-else
                      v-model="spaceInfo.spaceNm"
                    />
                    <div class="input_btn">
                      <p id="store_search1" class="on" @click="searchAddr('map')">매장검색</p>
                      <p style="margin-bottom: 4px; margin-top: 4px" @click="searchAddr('search')">직접입력</p>
                    </div>
                  </div>
                </label>
                <label
                  >매장주소 <span>*</span>
                  <div>
                    <input
                      type="text"
                      id="address2"
                      placeholder="매장명을 선택하면 자동으로 입력됩니다."
                      v-if="inputStatus === 'map'"
                      v-model="spaceInfo.spaceAddr"
                      :disabled="true"
                      style="margin-bottom: 8px"
                    />
                    <input
                      type="text"
                      id="address2"
                      placeholder="매장 주소를 검색해주세요."
                      v-else
                      v-model="spaceInfo.spaceAddr"
                      :disabled="true"
                    />
                    <div class="input_btn2">
                      <p style="margin-bottom: 0px" @click="searchAddress()">주소검색</p>
                    </div>
                    <input
                      type="text"
                      id="addressDetail2"
                      v-model="spaceInfo.spaceAddrDetail"
                      placeholder="상세주소를 입력해주세요."
                    />
                  </div>
                </label>
                <label style="position: relative">
                  소속 브랜드
                  <div>
                    <!-- <img src="/media/img/search_icon.svg" class="brand-search-img" @click="searchBrand()"/> -->
                    <input
                      type="text"
                      id="searchBrand"
                      placeholder="브랜드명을 검색해주세요."
                      v-model="searchBrandNm"
                      @click="searchBrand()"
                    />
                  </div>
                </label>
                <label id="tel">
                  연락처
                  <div>
                    <input
                      type="text"
                      placeholder="매장 연락처를  - 없이 입력해주세요."
                      v-model="spaceInfo.spaceTel"
                      @input="numCheck()"
                    />
                  </div>
                </label>
                <label
                  >사업자등록번호
                  <div>
                    <input type="text" placeholder="사업자등록번호를  - 없이 입력해주세요." v-model="spaceInfo.bno" />
                    <p class="btn_type" @click="checkBno()">확인</p>
                  </div>
                </label>
                <div>
                  <label>업종 <span>*</span></label>
                  <div class="select_box">
                    <div class="select-box-area">
                      <div
                        class="select-box select_box_big"
                        @mouseover.once="clickSelectBoxMo()"
                        v-if="spaceInfo.sector == null"
                      >
                        대분류
                      </div>
                      <div class="select-box select_box_big" @mouseover.once="clickSelectBoxMo()" v-else>
                        {{ spaceInfo.sector }}
                      </div>
                    </div>
                    <div class="select-box-area">
                      <div
                        class="select-box select_box_small"
                        @mouseover.once="clickSelectBoxMo2()"
                        v-if="spaceInfo.sectorDetail == null"
                      >
                        소분류
                      </div>
                      <div class="select-box select_box_small" @mouseover.once="clickSelectBoxMo2()" v-else>
                        {{ spaceInfo.sectorDetail }}
                      </div>
                    </div>
                  </div>
                </div>
                <label
                  >영업시간 <span>*</span>
                  <div class="set_clock">
                    <div class="start_clock" @click="selectStartTimeM">
                      <p style="margin-bottom: 0px">{{ userInfo.startTime }}</p>
                    </div>
                    ~
                    <div class="end_clock" @click="selectEndTimeM">
                      <p style="margin-bottom: 0px">{{ userInfo.endTime }}</p>
                    </div>
                  </div>
                </label>
                <label
                  >IP 스피커 사용여부 <span>*</span>
                  <div style="display: flex; justify-content: flex-start; align-items: center">
                    <checkboxUseIpSpeaker v-model="isIpSpeaker" />
                    <p style="margin: 0 6px 0">사용</p>
                  </div>
                </label>
              </form>
              <div class="form_bottom">
                <button
                  style="text-align: center"
                  v-if="
                    spaceId != null &&
                      spaceInfo.spaceNm != null &&
                      spaceInfo.spaceNm.length > 0 &&
                      spaceInfo.spaceAddr != null &&
                      spaceInfo.spaceAddr !== '' &&
                      spaceInfo.sector != null &&
                      spaceInfo.sectorDetail != null &&
                      spaceInfo.sectorDetail !== '소분류' &&
                      spaceInfo.startDate != null &&
                      spaceInfo.endDate != null
                  "
                  @click="inputStatus === 'search' ? updateCheckSpaceNm() : updateSpaceInfo()"
                >
                  다음
                </button>
                <button
                  :class="{ disabled: disabledInsert === true }"
                  style="text-align: center"
                  v-else-if="
                    spaceId == null &&
                      spaceInfo.spaceNm != null &&
                      spaceInfo.spaceNm.length > 0 &&
                      spaceInfo.spaceAddr != null &&
                      spaceInfo.spaceAddr !== '' &&
                      spaceInfo.sector != null &&
                      spaceInfo.sectorDetail != null &&
                      spaceInfo.sectorDetail !== '소분류' &&
                      spaceInfo.startDate != null &&
                      spaceInfo.endDate != null
                  "
                  @click="inputStatus === 'search' ? insertCheckSpaceNm() : insertSpace()"
                >
                  다음
                </button>
                <button class="opacity3" style="text-align: center" v-else>다음</button>
              </div>
              <div class="space_down_box1">
                <div>
                  <h2>대분류</h2>
                  <svg
                    @click="closeSelectBoxMo()"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0007 10.5862L16.9507 5.63623L18.3647 7.05023L13.4147 12.0002L18.3647 16.9502L16.9507 18.3642L12.0007 13.4142L7.05072 18.3642L5.63672 16.9502L10.5867 12.0002L5.63672 7.05023L7.05072 5.63623L12.0007 10.5862Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <ul style="padding-left: 0px">
                  <li
                    v-for="item in sectors"
                    :key="item.sectorId"
                    @click="[selectBoxMo(item.sectorId), getSectorDetails(item.sectorId)]"
                  >
                    <p :id="item.sectorId" style="margin-bottom: 0px">{{ item.sectorNm }}</p>
                  </li>
                </ul>
              </div>
              <div class="space_down_box2">
                <div>
                  <h2>소분류</h2>
                  <svg
                    @click="closeSelectBoxMo2()"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0007 10.5862L16.9507 5.63623L18.3647 7.05023L13.4147 12.0002L18.3647 16.9502L16.9507 18.3642L12.0007 13.4142L7.05072 18.3642L5.63672 16.9502L10.5867 12.0002L5.63672 7.05023L7.05072 5.63623L12.0007 10.5862Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <ul style="padding-left: 0px">
                  <li
                    v-for="item in sectorDetails"
                    :key="item.sectorDetailId"
                    @click="selectBoxMo2(item.sectorDetailId)"
                  >
                    <p :id="item.sectorDetailId" style="margin-bottom: 0px">{{ item.sectorDetailNm }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import StartPc from '@/components/modal/AutoPlay/StartPc.vue';
import EndPc from '@/components/modal/AutoPlay/EndPc.vue';
import StartMobile from '@/components/modal/AutoPlay/StartMobile.vue';
import EndMobile from '@/components/modal/AutoPlay/EndMobile.vue';
import CancelModal from '@/components/modal/Space/CancelModal.vue';
import CommonConfirm from '@/components/common/CommonConfirm.vue';
import Franchise from '@/components/modal/Space/Franchise.vue';
import searchStore from '@/components/map/searchStore.vue';
import checkboxUseIpSpeaker from '@/components/checkbox/checkboxUseIpSpeaker.vue';
import CheckDontUseIpSpeakerModal from '@/components/modal/Space/CheckDontUseIpSpeakerModal.vue';
import $ from 'jquery';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import dayjs from 'dayjs';
import router from '@/router';

export default defineComponent({
  data() {
    return {
      spaceLatitude: '',
      spaceLongitude: '',
      isMap: false,
      sectors: [],
      sectorDetails: [],
      spaceInfo: {
        sectorDetail: null,
        startDate: '09:00',
        endDate: '18:00'
      },
      spaceName: '',
      inputStatus: 'map',
      startStatus: false,
      endStatus: false,
      startStatusM: false,
      endStatusM: false,
      userInfo: {
        startTime: '09:00',
        endTime: '18:00'
      },
      cancelStatus: false,
      cancelCheckStatus: false,
      detailInfo: {},
      franchiseStatus: false,
      searchBrandNm: '',
      prevRoute: null,
      checkSpaceNm: false,
      disabledInsert: false,
      isIpSpeaker: true,
      isCheckDontUseIpSpeakerModal: false
    };
  },
  props: {
    spaceId: String,
    spaceinfoinsert: {
      type: Function,
      required: false,
      default: null
    },
    spaceinfoupdate: {
      type: Function,
      required: false,
      default: null
    },
    linkmyspace: {
      type: Function,
      required: false,
      default: null
    }
  },
  components: {
    searchStore,
    StartPc,
    EndPc,
    StartMobile,
    EndMobile,
    CancelModal,
    CommonConfirm,
    Franchise,
    checkboxUseIpSpeaker,
    CheckDontUseIpSpeakerModal
  },
  methods: {
    numCheck() {
      this.spaceInfo.spaceTel = this.spaceInfo.spaceTel.replace(/[^0-9]/g, '');
    },
    checkHasSpaceDontUseIpSpeaker() {
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/hasSpaceDontUseIpSpeaker', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            if (this.isIpSpeaker) {
              res.data.result === 1 ? (this.isCheckDontUseIpSpeakerModal = true) : (this.isIpSpeaker = false);
            } else {
              this.isIpSpeaker = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    checkHasSpaceDontUseIpSpeaker2() {
      // 페이지 진입 시 사용
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/hasSpaceDontUseIpSpeaker', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            if (this.isIpSpeaker) {
              res.data.result === 1 ? (this.isCheckDontUseIpSpeakerModal = true) : (this.isIpSpeaker = true);
            } else {
              this.isIpSpeaker = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getLatLng() {
      var kakao = window.kakao;
      var geocoder = new kakao.maps.services.Geocoder();

      geocoder.addressSearch(this.spaceInfo.spaceAddr, (result, status) => {
        if (status === kakao.maps.services.Status.OK) {
          this.spaceInfo.spaceLongitude = result[0].x;
          this.spaceInfo.spaceLatitude = result[0].y;
        }
      });
    },
    closeModal() {
      this.checkSpaceNm = false;
      this.spaceInfo.spaceNm = '';
      this.spaceName = '';
      if (this.inputStatus === 'map') {
        this.spaceInfo.spaceAddr = '';
        this.spaceInfo.spaceAddrDetail = '';
      }
      document.getElementById('search').focus();
    },
    closeFranchise() {
      this.franchiseStatus = false;
      this.searchBrandNm = '';
    },
    closeMap() {
      this.isMap = false;
      this.spaceInfo.spaceNm = '';
    },
    insertBrand(item) {
      this.franchiseStatus = false;
      this.searchBrandNm = item.brandNm;
      this.spaceInfo.brandId = item.brandId;
    },
    searchBrand() {
      this.franchiseStatus = true;
    },
    insertCheckSpaceNm() {
      var spaceNm = this.spaceInfo.spaceNm;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/checkSpaceNm', { spaceNm }, { headers })
        .then(res => {
          // console.log(res.data);
          if (res.data.resultCd === '0000') {
            if (res.data.result === 0) {
              this.insertSpace();
            } else {
              this.checkSpaceNm = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateCheckSpaceNm() {
      var spaceNm = this.spaceInfo.spaceNm;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/checkSpaceNm', { spaceNm }, { headers })
        .then(res => {
          // console.log(res.data);
          if (res.data.resultCd === '0000') {
            if (res.data.result === 0) {
              this.updateSpaceInfo();
            } else {
              this.checkSpaceNm = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    insertSpace() {
      this.disabledInsert = true;
      this.isIpSpeaker === true ? (this.spaceInfo.useIpSpeaker = 'Y') : (this.spaceInfo.useIpSpeaker = 'N');
      var spaceType = 'Building';
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/createSpaceInfo', { userId, spaceType }, { headers })
        .then(res => {
          var spaceId = res.data.result;
          localStorage.setItem('spaceId', spaceId);
          this.spaceInfo.spaceId = spaceId;

          axios
            .post('/api/profile/createCurationInfo', { spaceId }, { headers })
            .then(response => {
              axios
                .post('/api/profile/createPreferMusicList', { spaceId }, { headers })
                .then(res1 => {
                  var spaceInfo = this.spaceInfo;
                  axios
                    .post('/api/profile/updateSpaceInfo', spaceInfo, { headers })
                    .then(res2 => {
                      if (res2.data.resultCd === '0000') {
                        this.disabledInsert = false;
                        this.$router.push({ name: 'SpaceInsertStoreColor' }).catch(() => {});
                      }
                    })
                    .catch(err2 => {
                      this.disabledInsert = false;
                      console.log(err2);
                    });
                })
                .catch(err1 => {
                  this.disabledInsert = false;
                  console.log(err1);
                });
            })
            .catch(error => {
              this.disabledInsert = false;
              console.log(error);
            });
        })
        .catch(err => {
          this.disabledInsert = false;
          console.log(err);
        });
    },
    updateSpaceInfo() {
      // 매장 정보 수정
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      var spaceInfo = this.spaceInfo;
      VueCookies.remove('spaceNm'); // 쿠키 스페이스명 삭제

      axios
        .post('/api/profile/updateSpaceInfo', spaceInfo, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.$router.push({ name: 'SpaceInsertStoreColor' }).catch(() => {});
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getSpaceInfo() {
      // 등록된 매장정보 가져오기
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.spaceInfo = res.data.result;

          if (this.spaceInfo.brandId != null && this.spaceInfo.brandId !== '') {
            this.getBrandInfo();
          }
          this.getSectors();

          if (this.spaceInfo.startDate != null) {
            this.userInfo.startTime = this.spaceInfo.startDate;
          } else {
            this.userInfo.startTime = '09:00';
            this.spaceInfo.startDate = '09:00';
          }

          if (this.spaceInfo.endDate != null) {
            this.userInfo.endTime = this.spaceInfo.endDate;
          } else {
            this.userInfo.endTime = '18:00';
            this.spaceInfo.endDate = '18:00';
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getBrandInfo() {
      var brandId = this.spaceInfo.brandId;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/getBrand', { brandId }, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.searchBrandNm = res.data.result.brandNm;
        })
        .catch(err => {
          console.log(err);
        });
    },
    linkMySpace() {
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      if (spaceId) {
        axios
          .post('/api/profile/checkSubscribe', { spaceId }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              if (res.data.result === 'S') {
                this.spaceId = localStorage.getItem('spaceId');
                this.cancelCheckStatus = true;
              } else {
                router.push({ name: 'Player' });
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.clickPre();
      }
    },
    clickPre() {
      history.back();
    },
    setSpaceInfo(spaceInfo) {
      // 매장검색을 통해 매장명, 주소 값 받기
      this.spaceInfo.spaceNm = spaceInfo.spaceNm;
      this.spaceInfo.spaceAddr = spaceInfo.spaceAddr;
      this.spaceInfo.spaceLatitude = spaceInfo.y;
      this.spaceInfo.spaceLongitude = spaceInfo.x;
      this.spaceInfo.spaceAddrDetail = '';
    },
    showMap() {
      // 매장검색 모달 열기
      this.spaceName = this.spaceInfo.spaceNm;
      this.isMap = true;
      $('.search-addr').hide();
      $('.input_btn2').hide();
    },
    searchAddr(select) {
      // 클릭한 버튼 색상 변경
      this.inputStatus = select;
      $(document).on('click', '.input_btn p', function() {
        $('.input_btn')
          .find('p')
          .removeClass('on');
        $(this).addClass('on');
      });

      if (select === 'map') {
        this.showMap();
        this.spaceInfo.spaceAddr = '';
        this.spaceInfo.spaceAddrDetail = '';
      } else {
        $('.search-addr').show();
        $('.input_btn2').show();
        this.spaceInfo.spaceAddr = '';
        this.spaceInfo.spaceAddrDetail = '';
      }
    },
    searchAddress() {
      // 주소검색
      new window.daum.Postcode({
        oncomplete: data => {
          var addr = '';

          // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === 'R') {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }
          // 주소 정보를 해당 필드에 넣는다.
          document.getElementById('address').value = addr;
          document.getElementById('address2').value = addr;
          // 커서를 상세주소 필드로 이동한다.
          this.spaceInfo.spaceAddrDetail = '';
          document.getElementById('addressDetail').focus();
          this.spaceInfo.spaceAddr = addr;
          setTimeout(() => {
            this.getLatLng();
          }, 1000);
        }
      }).open();
    },
    checkBno() {
      // 사업자등록번호 체크
      var bzno = this.spaceInfo.bno;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/user/getBzno', { bzno }, { headers })
        .then(res => {
          // console.log(res.data.result);
          var result = res.data.result;
          if (result === 1) {
            alert('정상적인 사업자등록번호 입니다.');
          } else if (result === 2) {
            alert('등록되지않은 사업자등록번호 입니다.');
            this.spaceInfo.bno = '';
          } else {
            alert('휴/폐업된 사업자등록번호 입니다.');
            this.spaceInfo.bno = '';
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getSectors() {
      // 업종 목록 가져오기
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/profile/getSectorList', null, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.sectors = res.data.result;

          var detailNm = this.spaceInfo.sector;
          for (var i in this.sectors) {
            var detailId = this.sectors[i].sectorNm.indexOf(detailNm);
            if (detailId === 0) {
              this.detailInfo = this.sectors[i];
              this.getSectorDetails(this.detailInfo.sectorId);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getSectorDetails(item) {
      // 업종상세 목록 가져오기
      var sectorId = item;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/getSectorDetailList', { sectorId }, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.sectorDetails = res.data.result;
        })
        .catch(err => {
          console.log(err);
        });
    },
    dateTime(value) {
      return dayjs(value).format('HH:mm');
    },
    clickSelectBox() {
      /* select_box_custom */
      var label = document.querySelectorAll('.label');
      label.forEach(function(lb) {
        lb.addEventListener('click', e => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function(itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach(opt => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          // console.log(lb.parentNode.classList);
          optionItems.forEach(opt => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.spaceInfo.sector = item.textContent;
        this.spaceInfo.sectorDetail = '소분류';
        // label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function(lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    },
    clickSelectBox2() {
      /* select_box_custom */
      var label = document.querySelectorAll('.label2');
      label.forEach(function(lb) {
        lb.addEventListener('click', e => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem2');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function(itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach(opt => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          // console.log(lb.parentNode.classList);
          optionItems.forEach(opt => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.spaceInfo.sectorDetail = item.textContent;
        // label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label2') && !e.target.classList.contains('optionItem2')) {
          label.forEach(function(lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    },
    clickSelectBoxMo() {
      $(document)
        .off('click')
        .on('click', '.select_box_big', () => {
          $('.back_dim').show();
          $('.space_down_box1').css({ bottom: 0, transition: 'all 0.5s' });
        });
    },
    closeSelectBoxMo() {
      $('.space_down_box1')
        .find('svg')
        .click(function() {
          $('.back_dim').hide();
          $('.space_down_box1').css({ bottom: '-100%', transition: 'all 1s' });
        });
    },
    selectBoxMo(index) {
      this.spaceInfo.sector = document.getElementById(index).innerHTML;
      if (this.spaceInfo.sectorDetail != null) {
        // console.log(this.spaceInfo.sectorDetail);
        this.spaceInfo.sectorDetail = '';
      }
      $('.back_dim').hide();
      $('.space_down_box1').css({ bottom: '-100%', transition: 'all 1s' });
    },
    clickSelectBoxMo2() {
      $('.select_box_small').click(function() {
        $('.back_dim').show();
        $('.space_down_box2').css({ bottom: '0', transition: 'all 0.5s' });
      });
    },
    closeSelectBoxMo2() {
      $('.space_down_box2')
        .find('svg')
        .click(function() {
          $('.back_dim').hide();
          $('.space_down_box2').css({ bottom: '-100%', transition: 'all 1s' });
        });
    },
    selectBoxMo2(index) {
      this.spaceInfo.sectorDetail = document.getElementById(index).innerHTML;
      $('.back_dim').hide();
      $('.space_down_box2').css({ bottom: '-100%', transition: 'all 1s' });
    },
    getKakaoMap() {
      if (!window.kakao) {
        const script = document.createElement('script');
        script.src =
          '//dapi.kakao.com/v2/maps/sdk.js?appkey=9d67727587f68495b58ce27d5adc27e1&autoload=false&libraries=services';
        document.head.appendChild(script);
      }
    },
    getDaumcdn() {
      if (!window.daum) {
        const script = document.createElement('script');
        script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
        document.head.appendChild(script);
      }
    },
    set() {
      $('.search-addr').hide();
      $('.input_btn2').hide();
    },
    selectStartTime() {
      this.startStatus = true;
    },
    selectEndTime() {
      this.endStatus = true;
    },
    insertStartTime(time) {
      var hour = time.hour.substring(0, 2);
      var minute = time.minute.substring(0, 2);
      this.userInfo.startTime = hour + ':' + minute;
      this.spaceInfo.startDate = this.userInfo.startTime;
      this.startStatus = false;
    },
    insertEndTime(time) {
      var hour = time.hour.substring(0, 2);
      var minute = time.minute.substring(0, 2);
      this.userInfo.endTime = hour + ':' + minute;
      this.spaceInfo.endDate = this.userInfo.endTime;
      this.endStatus = false;
    },
    selectStartTimeM() {
      this.startStatusM = true;
    },
    selectEndTimeM() {
      this.endStatusM = true;
    },
    insertStartTimeM(time) {
      var hour = time.hour.substring(0, 2);
      var minute = time.minute.substring(0, 2);
      this.userInfo.startTime = hour + ':' + minute;
      this.spaceInfo.startDate = this.userInfo.startTime;
      this.startStatusM = false;
    },
    insertEndTimeM(time) {
      var hour = time.hour.substring(0, 2);
      var minute = time.minute.substring(0, 2);
      this.userInfo.endTime = hour + ':' + minute;
      this.spaceInfo.endDate = this.userInfo.endTime;
      this.endStatusM = false;
    },
    /**
     * @description 임시저장 팝업 아니오 클릭
     */
    onCancelCheckStatusConfirmNo() {
      this.linkmyspace();
    },
    /**
     * @description 임시저장 팝업 예 클릭
     */
    async onCancelCheckStatusConfirmYes() {
      if (this.spaceId) {
        const result = await this.spaceinfoupdate(this.spaceInfo);
        if (result) {
          this.cancelCheckStatus = false;
          this.cancelStatus = true;
        } else {
          alert(`오류가 발생하였습니다`);
        }
      } else {
        const { isSuccess, resultMsg } = await this.spaceinfoinsert(
          'Building',
          this.$cookies.get('userId'),
          this.spaceInfo
        );
        if (isSuccess) {
          this.cancelCheckStatus = false;
          this.cancelStatus = true;
        } else {
          alert(resultMsg);
        }
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  async mounted() {
    this.getDaumcdn();
    this.getKakaoMap();
    this.set();
    if (this.spaceId != null) {
      this.checkHasSpaceDontUseIpSpeaker2();
    }
    setTimeout(() => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('aplayzaos') !== -1) {
        document.getElementsByClassName('mo_space_header')[0].style.padding = '12px 0 0';
      } else if (userAgent.indexOf('aplayznativeaos') !== -1) {
        document.getElementsByClassName('mo_space_header')[0].style.padding = '12px 0 0';
      }
    }, 1000);
    await this.getSectors();
    if (this.prevRoute) {
      // && this.prevRoute.path === '/space/insertStoreColor'
      this.spaceId = localStorage.getItem('spaceId');
      await this.getSpaceInfo();
    } else {
      if (this.spaceId === localStorage.getItem('spaceId') && localStorage.getItem('spaceId') !== null) {
        await this.getSpaceInfo();
      } else {
        this.spaceInfo.sectorDetail = null;
        if (this.spaceInfo.startDate != null) {
          this.userInfo.startTime = this.spaceInfo.startDate;
        } else {
          this.userInfo.startTime = '09:00';
          this.spaceInfo.startDate = '09:00';
        }

        if (this.spaceInfo.endDate != null) {
          this.userInfo.endTime = this.spaceInfo.endDate;
        } else {
          this.userInfo.endTime = '18:00';
          this.spaceInfo.endDate = '18:00';
        }
      }
    }
  }
});
</script>

<style scoped>
.disabled {
  pointer-events: none;
}

.modal {
  display: block;
}

.myspace_content_form .input_btn p.on {
  background-color: white;
  color: black;
}

.myspace_content_form .input_btn p.on:hover {
  opacity: 0.8;
}

.myspace_content_form .search-addr {
  position: absolute;
  top: 25%;
  right: 2px;
  transform: translateY(-60%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.myspace_content_form .search-addr {
  padding: 8px 16px;
  background: transparent;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
}

.myspace_content_form .search-addr {
  margin-right: 7px;
  background: #fff;
  color: #151515;
}

.myspace_content_form .search-addr:hover {
  background: rgba(255, 255, 255, 0.8);
}

.input_btn2 {
  position: absolute;
  top: 25%;
  right: 10px;
  transform: translateY(-60%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_btn2 {
  padding: 8px 12px;
  background: white;
  color: black;
  border-radius: 8px;
}

@media all and (max-width: 500px) {
  .select-box-dropDown ul {
    padding: 0;
  }

  .space_down_box1 ul li {
    margin-bottom: 12px;
  }

  .space_down_box2 ul li {
    margin-bottom: 12px;
  }

  .brand-search-img {
    position: absolute;
    top: 40%;
    left: 2.5%;
    size: 20px;
    width: 20px;
  }

  .select_box {
    margin-bottom: 24px;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style scoped src="@/assets/css/modal/space/playstop.css"></style>
